import staticsFn from "./statics";
staticsFn();
var _window = window,
    aplus_queue = _window.aplus_queue;
aplus_queue.push({
  action: "aplus.sendPV",
  arguments: [{
    is_auto: false
  }, {
    param1: 111,
    param2: "222"
  }]
});
document.title = "中国质检协会机动车服务平台01";
export default {
  setup: function setup() {
    var toPage = function toPage() {
      window.open("https://chejian.snuo.net/apply#/");
    };

    return {
      toPage: toPage
    };
  }
};