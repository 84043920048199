let BASE_URL = '';
let FILE_URL = '';
/**
 * 测试
 * @description
 * 
 */
 BASE_URL = 'https://test-api.snuo.net';
 FILE_URL =  'https://test-api.snuo.net/AiVo/getAiVoByFile'

 /**
 * 生产
 * @description
 * 
 */
  // BASE_URL = 'https://api.snuo.net';
  // FILE_URL =  'https://api.snuo.net/AiVo/getAiVoByFile'

export {
    BASE_URL,
    FILE_URL,
}